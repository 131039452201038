/* src/fonts.css */
@font-face {
  font-family: "Calibri";
  src: url("./assets/fonts/Calibri-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./assets/fonts/Calibri-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./assets/fonts/Calibri-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
