@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Calibri", sans-serif;
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-modal-wrap{
  z-index: 10000000 !important;
}

.ant-modal-body::-webkit-scrollbar {
  display: none !important;
}

/* @media print {
  @page {
    size: landscape; 
    margin: 0; 
  }

  body * {
    visibility: hidden;
  }

  .section-to-print, .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    width: 100vw; 
    height: 100vh; 
    margin: 0;
    padding: 0;
  }
} */


.no_scroll::-webkit-scrollbar {
  display: none !important;
}

.rpv-core__inner-pages::-webkit-scrollbar{
  display: none !important;
}

.css-1nmdiq5-menu{
  background-color: white !important;
}

@media screen and (max-width:500px) {
  .css-1mfkup6{
    padding: 0 !important;
  }

  .css-1hd3did{
    padding: 0 !important;
  }
}

.ant-modal-content{
  z-index: 50 !important;
}